.p-relative {
  position: relative;
  z-index: 1;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.flex-end {
  justify-content: flex-end;
}

.carousel-rounded .carouselSlide:nth-child(1) img{
  border-radius: var(--radius-l) 0 0 var(--radius-l);
}
.carousel-rounded .carouselSlide:last-child:not(:first-child) img{
  border-radius: 0 var(--radius-l) var(--radius-l) 0;
}

.linkButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  line-height: 1.2;
  padding: var(--spacing-12) var(--spacing-24);
  border-width: 2px;
  border-style: solid;
  transition: .2s ease-in-out;
  transition-property: background-color, color, border-color, opacity, padding, transform;
  border-radius: var(--radius-xl);
  font-weight: 900;
  background-color: var(--b-green);
  color: var(--white);
  border-color: var(--b-green);
}
.linkButton:hover{
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}