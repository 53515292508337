.d-flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.d-inline-flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.d-flex.f-wrap, .d-inline-flex.f-wrap {
  flex-wrap: wrap;
}

.d-flex.center, .d-inline-flex.center {
  align-items: center;
  justify-content: center;
}

.d-flex.a-start, .d-inline-flex.a-start {
  align-items: flex-start;
}

.d-flex.a-end, .d-inline-flex.a-end {
  align-items: flex-end;
}

.d-flex.a-center, .d-inline-flex.a-center {
  align-items: center;
}

.d-flex.a-stretch, .d-inline-flex.a-stretch {
  align-items: stretch;
}

.d-flex.j-start, .d-inline-flex.j-start {
  justify-content: flex-start;
}

.d-flex.j-end, .d-inline-flex.j-end {
  justify-content: flex-end;
}

.d-flex.j-center, .d-inline-flex.j-center {
  justify-content: center;
}

.d-flex.j-space-btw, .d-inline-flex.j-space-btw {
  justify-content: space-between;
}

.d-flex.j-space-around, .d-inline-flex.j-space-around {
  justify-content: space-around;
}

.d-flex.d-col, .d-inline-flex.d-col {
  flex-direction: column;
}

.d-flex.d-row, .d-inline-flex.d-row {
  flex-direction: row;
}

.d-flex.d-col-reverse, .d-inline-flex.d-col-reverse {
  flex-direction: column-reverse;
}

.d-flex.d-row-reverse, .d-inline-flex.d-row-reverse {
  flex-direction: row-reverse;
}

@media (width <= 768px) {
  .d-flex.mobile-a-start, .d-inline-flex.mobile-a-start {
    align-items: flex-start;
 }
  .d-flex.mobile-a-stretch, .d-inline-flex.mobile-a-stretch {
    align-items: stretch;
 }
  .d-flex.mobile-a-end, .d-inline-flex.mobile-a-end {
    align-items: flex-end;
 }
  .d-flex.mobile-a-center, .d-inline-flex.mobile-a-center {
    align-items: center;
 }
  .d-flex.mobile-j-end, .d-inline-flex.mobile-j-end {
    justify-content: flex-end;
 }
  .d-flex.mobile-j-start, .d-inline-flex.mobile-j-start {
    justify-content: flex-start;
 }
  .d-flex.mobile-j-center, .d-inline-flex.mobile-j-center {
    justify-content: center;
 }
  .d-flex.mobile-j-space-btw, .d-inline-flex.mobile-j-space-btw {
    justify-content: space-between;
 }
  .d-flex.mobile-d-col, .d-inline-flex.mobile-d-col {
    flex-direction: column;
 }
  .d-flex.mobile-d-col-reverse, .d-inline-flex.mobile-d-col-reverse {
    flex-direction: column-reverse;
 }
  .d-flex.mobile-d-reverse, .d-inline-flex.mobile-d-reverse {
    flex-direction: row-reverse;
 }
  .d-flex.mobile-f-wrap, .d-inline-flex.mobile-f-wrap {
    flex-wrap: wrap;
 }
}

@media (width <= 1024px) {
  .d-flex.tablet-a-center, .d-inline-flex.tablet-a-center {
    align-items: center;
 }
  .d-flex.tablet-d-col, .d-inline-flex.tablet-d-col {
    flex-direction: column;
 }
  .d-flex.tablet-j-start, .d-inline-flex.tablet-j-start {
    justify-content: flex-start;
 }
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-full {
  flex: 1;
}

.flex-auto-shrink{
  flex: 1 0 auto;
}