/* Text alignments */
.text-center {
  text-align: center;
}

@media (width <= 768px) {
  .mobile-text-left {
    text-align: left;
  }
}

/* Text decoration */
.underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

/* Text break and whitespace */

.text-nowrap {
  white-space: nowrap;
}

.word-break-all {
  word-break: break-all;
}