.tippy-box[data-animation="fade"][data-state="hidden"] { opacity: 0; }

.tippy-box[data-theme~='customtheme'] {
  padding: var(--spacing-12);
  background-color: var(--yellow-200);
  box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius-s);
}

.tippy-box .tippy-content,
.tippy-box .tippy-content > div {
  padding: 0;
  font: var(--font-p2-desktop-default);
  color: var(--black);
}

@media (width <= 768px) {
  .tippy-box .tippy-content,
  .tippy-box .tippy-content > div {
    font: var(--font-p2-mobile-default);
  }
}

.tippy-box[data-theme~='customtheme'] .tippy-arrow {
  color: var(--yellow-200);
}

